/** CUSTOM STYLES FOR CUSTOM COMPONENTS **/
.ag-footer-no-pagination {
  height: 50px;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  background-color: var(--op2mise-color-white);
  border-top: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0 0 6px 6px;
  box-shadow: 0 10px 20px rgba(0,0,0,.1);
  position: relative;
}

.ag-op2mise-button {
  display: flex;
  align-items: center;
  background-color: var(--op2mise-color-primary);
  font-weight: 100;
  padding: 5px 15px;
  height: 32px;
  width: fit-content;
  border: none;
  border-radius: 4px;
  color: var(--op2mise-color-white);
  text-align: center;
  cursor: pointer;
}

.ag-op2mise-button:disabled {
  background-color: #b1b1b1;
}

.ag-overlay-no-rows-center{
  font-size: 30px;
  font-weight: 300;
  line-height: 45px;
  color: var(--op2mise-color-primary);
}